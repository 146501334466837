<template>
  <div class="shop-list">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="content">
      <top-content></top-content>
      <page-content
        :dataList="shopList"
        class="page-content"
        :contentTableConfig="contentTableConfig"
        @tableCellClick="handleTableCellClick"
      >
        <template #shopName="scope">
          <div class="shopName">
            <img
              v-if="scope.row.picture"
              :src="scope.row.picture"
              alt
            />
            <div class="name">{{ scope.row.shopName }}</div>
          </div>
        </template>
        <template #shopSquare="scope"> {{ scope.row.shopSquare }}㎡ </template>
        <template #openDate="scope">{{
          dayjs(scope.row.openDate).format("YYYY年MM月DD日")
        }}</template>
        <template #shopKind="scope">{{
          scope.row.shopKind == 1 ? "自营" : "加盟"
        }}</template>
        <template #handler="scope">
          <div class="handle-btns">
			<el-button plain @click.stop="handleTableCellClick(scope.row)" size="small"
              >详情</el-button
            >
            <el-button plain @click.stop="handleEditClick(scope.row)" size="small" v-has="'shop:edit'"
              >编辑</el-button
            >
            <div class="handle-wrap">
              <template v-if="scope.row.runningStatus == 1">
                <el-switch active-value="1" inactive-value="0" v-model="active" />
              </template>
              <template v-else>
                <el-switch active-value="1" inactive-value="0" v-model="inactive" />
              </template>
              <div @click.stop="hanldeStateClick(scope.row)" class="el-switch-mask"></div>
            </div>
          </div>
        </template>
      </page-content>
      <div class="el-pagination">
        <el-pagination
          v-model:currentPage="queryInfo.page"
          v-model:page-size="queryInfo.pagesize"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="shopCount"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>

      <shop-edit-draw
        :goodsId="currentShopsId"
        @changedDrawer="handleGoodsEditdDrawer"
        :dialogVisible="pageDialogVisible"
      ></shop-edit-draw>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config/index'
import TopContent from './cpns/top-content/top-content.vue'
import { COMPANY_ID } from '@/common/constant'
import { useRouter } from 'vue-router'
import store from '@/store'
import dayjs from 'dayjs'
import ShopEditDraw from './cpns/shop-edit-draw/shop-edit-draw.vue'
import { UPLOAD_BASE_URL } from '@/common/constant'
import emitter from '@/utils/eventbus'
import { ElMessage } from 'element-plus'
import {
  stopShop,
} from '@/service/main/shop'
export default defineComponent({
  props: {},
  components: {
    Breadcrumb,
    PageContent,
    TopContent,
    ShopEditDraw
  },
  name: "shopList",
  setup() {
    const router = useRouter()
    const handleEditClick = (value) => {
      router.push({
        path: '/main/shop/update', query: {
          shopId: value.shopId
        }
      })
      // console.log(value);
    }

    const queryInfo = reactive({
      companyId: COMPANY_ID,
      keyword: '',
      page: 1,
      pagesize: 10
    })

    const shopCount = computed(() => store.state.shop.shopCount)
    const shopList = computed(() => store.state.shop.shopList)

    const initPage = () => {
      store.dispatch('shop/getshopListAction', { ...queryInfo })
    }
    initPage()

    const handleCurrentChange = page => {
      queryInfo.page = page
      initPage()
    }

    const handleSizeChange = pagesize => {
      queryInfo.pagesize = pagesize
      initPage()
    }


    emitter.on('changeShopTopcontentSearchClick', keyword => {
      queryInfo.keyword = keyword
      initPage()
    })

    emitter.on('refreshShopListPageContent', () => {
      initPage()
    })

    const currentShopsId = ref('')
    const pageDialogVisible = ref(false)
    const handleGoodsEditdDrawer = (flag) => {
      pageDialogVisible.value = flag
    }

    const hanldeStateClick = async (value) => {
      let status
      if (value.runningStatus == 1) {
        status = 2
      } else {
        status = 1
      }

      const res = await stopShop({
        shopId: value.shopId,
        status,
        companyId: COMPANY_ID
      })

      if (res.code == 0) {
        ElMessage({
          message: '操作成功！',
          type: 'success',
        })
        initPage()
      }
    }

    const handleTableCellClick = (item) => {
      currentShopsId.value = item.shopId
      pageDialogVisible.value = true
    }


    onUnmounted(() => {
      emitter.off('changeShopTopcontentSearchClick')
      emitter.off('refreshShopListPageContent')

    })

    const active = ref('1')
    const inactive = ref('0')

    return {
      inactive,
      active,
      queryInfo,
      breadcrumbList,
      contentTableConfig,
      COMPANY_ID,
      handleEditClick,
      router,
      dayjs,
      currentShopsId,
      pageDialogVisible,
      handleGoodsEditdDrawer,
      handleTableCellClick,
      hanldeStateClick,
      UPLOAD_BASE_URL,
      handleCurrentChange,
      handleSizeChange,
      shopList,
      shopCount
    }
  }
})
</script>

<style scoped lang="less">
.shop-list {
  min-width: 1080px;

  .handle-btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .handle-wrap {
      position: relative;
    }
    &:deep(.el-button) {
      margin-right: 12px;
    }
  }

  .content {
    margin: 20px;
    padding: 10px 20px;
    background-color: white;

    .page-content {
    }
  }

  .shopName {
    display: flex;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }

  .address {
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 30px;
    }

    div {
      text-align: left;
    }
  }
}
</style>
