<template>
  <el-scrollbar height="100px">
    <div class="goods-edit-draw">
      <page-draw v-if="pageDialogVisible" @changedDrawer="changedDrawer" :isShow="pageDialogVisible">
        <div class="shop-detail">
          <div class="top-form">
            <div class="shop-name">{{ shopDetail && shopDetail.shopName }}店</div>
            <div>
              <el-button @click="handleEditClick" type="primary" v-has="'shop:edit'">编辑</el-button>
              <el-button v-if="shopDetail && shopDetail.runningStatus == 1" @click="handleOperateClick(2)" type="info">
                停用</el-button>
              <el-button v-else @click="handleOperateClick(1)" type="primary">启用</el-button>
            </div>
          </div>
          <div class="goods-img">
            <template v-if="imgList.length > 0">
              <pageSwiper :picList="shopDetail.picture"></pageSwiper>
            </template>


            <template v-else>
              <div class="img-tip"></div>
            </template>
          </div>
          <div class="goods-name">{{ updateGoodsDetail && updateGoodsDetail.goodName }}</div>
          <div class="base-info">
            <div class="title">
              <div>基本信息</div>
            </div>
            <div class="base-info-list">
              <div class="info-item">
                <div class="item-name">门店地址:</div>
                <div class="value">{{ shopDetail && shopDetail.shopAddr }}</div>
              </div>
            </div>
          </div>

          <div class="base-info">
            <div class="title">
              <div>营业信息</div>
            </div>
            <div class="base-info-list">
              <div class="desc">{{ shopDetail && shopDetail.shopIntro }}</div>
              <div class="info-item">
                <div class="item-name">营业时间:</div>
                <div class="value">{{ shopDetail && shopDetail.shopRuntime }}</div>
              </div>
              <div class="info-item">
                <div class="item-name">门店类型:</div>
                <div v-if="shopDetail.shopKind" class="value">{{
                    shopDetail && shopDetail.shopKind == 1 ? "自营" : "加盟"
                }}</div>
              </div>
            </div>
          </div>

          <div class="base-info">
            <div class="title">
              <div>坪效信息</div>
            </div>
            <div class="base-info-list">
              <div class="info-item">
                <div class="item-name">开业时间:</div>
                <div class="value">{{ shopDetail && dayjs(shopDetail.openDate).format('YYYY年MM月DD日') }}</div>
              </div>
              <div class="info-item">
                <div class="item-name">建筑面积:</div>
                <div class="value">{{ shopDetail && shopDetail.shopSquare }}平方米</div>
              </div>

              <div class="info-item">
                <div class="item-name">实际面积:</div>
                <div class="value">{{ shopDetail && shopDetail.shopUseSquare }}平方米</div>
              </div>
            </div>
          </div>

          <div class="format-info">
            <div class="title">
              <div>房间及床位信息</div>
            </div>
            <div class="titles">
              <div>房间编号</div>
              <div>床位数量</div>
            </div>
            <div class="shop-list">
              <template :key="item" v-for="item in shopDetail.roomList">
                <div class="shop-item">
                  <div>{{ item.roomNo }}</div>
                  <div>{{ item.bedQty }}张</div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </page-draw>
    </div>
  </el-scrollbar>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch, onMounted, onUnmounted } from 'vue'
import PageDraw from '@/components/page-drawer/page-drawer.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { useState } from '@/hooks'
import { UPLOAD_BASE_URL } from '@/common/constant'
import { categoryProps } from './config'
import { useRouter } from 'vue-router'
import emitter from '@/utils/eventbus'
import dayjs from 'dayjs'
import pageSwiper from '@/components/page-swiper/page-swiper.vue'


export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    goodsId: {
      type: Number,
      default: 1
    }
  },
  components: {
    PageDraw,
    pageSwiper,

  },
  emits: ['changedDrawer'],
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const pageDialogVisible = computed(() => props.dialogVisible)
    const formatList = ref([])
    const changedDrawer = (flag) => {
      emit('changedDrawer', flag)
    }

    onMounted(() => {

    })

    const formState = useState('shop', ['shopBedList'])

    watch(() => props.goodsId, () => {
      initPage()
    })

    const handleEditClick = () => {
      router.push({
        path: '/main/shop/update',
        query: {
          shopId: props.goodsId
        }
      })
    }

    const handleOperateClick = (type) => {
      store.dispatch('shop/setShopStateAction', {
        shopId: props.goodsId,
        status: type,
        companyId: COMPANY_ID
      })
    }

    const imgList = ref([
      'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F4k%2Fs%2F02%2F2109242342133248-0-lp.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1656174588&t=59e0da902bbc5e37183fc5c858478b74',
      'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1115%2F102R11Q228%2F21102QQ228-9-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1656174588&t=cd8c28fea89c190e1a3a38bbde1cecbc',
      'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fbkimg.cdn.bcebos.com%2Fpic%2F48540923dd54564e466a1884b5de9c82d1584f04&refer=http%3A%2F%2Fbkimg.cdn.bcebos.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1656174588&t=4d1bfc50058ee7b9c6fb2b8825015554'
    ])

    const shopDetail = computed(() => store.state.shop.shopDetail)
    const shopPicList = computed(() => store.state.shop.shopPicList)
    const shopBedList = computed(() => store.state.shop.shopBedList)


    watch(shopDetail, (n) => {
      // console.log(n);
    })

    watch(shopPicList, (n) => {
      // console.log(n);
    })

    emitter.on('refreshShopEditDrawPage', () => {
      initPage()
    })

    const initPage = () => {
      store.dispatch('shop/getShopDetailAction', props.goodsId)
    }

    onUnmounted(() => {
      emitter.off('refreshShopEditDrawPage')
    })


    return {
      imgList,
      pageDialogVisible,
      changedDrawer,
      formatList,
      UPLOAD_BASE_URL,
      ...formState,
      categoryProps,
      handleEditClick,
      handleOperateClick,
      shopPicList,
      shopDetail,
      shopBedList,
      dayjs,
    }

  }
})
</script>

<style scoped lang="less">
.goods-edit-draw {
  font-size: 12px;

  &:deep(.swiper-pagination-bullet) {
    background-color: white !important;
  }

  .shop-detail {
    height: 100%;

    .title {
      display: flex;
      position: relative;
      justify-content: space-between;
      padding-left: 16px;
      color: rgba(80, 80, 80, 1);
      font-size: 14px;
      margin-bottom: 10px;

      .other {
        color: rgba(42, 130, 228, 1);

        span {
          margin: 0 6px;
          cursor: pointer;
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: 4px;
        height: 20px;
        background-color: rgba(42, 130, 228, 1);
        left: 0;
      }
    }

    .goods-name {
      color: rgb(42, 130, 228);
      font-size: 18px;
      font-weight: 550;
      margin: 16px 0;
    }

    .top-form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    .goods-img {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;


      .carousel {
        width: 100%;
      }
    }

    .base-info {
      margin-top: 22px;

      .base-info-list {
        margin-top: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;

        .desc {
          font-size: 14px;
          margin-bottom: 15px;
          padding: 10px;
          background: #feffe7;
          border-radius: 5px;
          color: #ff6600;
          line-height: 22px;
        }

        .info-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;
          margin: 0 1%;
          margin-bottom: 16px;

          .item-name {
            width: 60px;
            color: rgba(166, 166, 166, 1);
            font-size: 12px;
            margin-right: 26px;
          }

          .value {
            color: rgba(80, 80, 80, 1);
            font-size: 12px;
          }
        }
      }
    }

    .format-info {
      margin: 20px 0;

      .shop-list {
        .shop-item {
          display: flex;
          margin: 10px 0;

          div {
            flex: 1;
            padding: 0 15px;
          }
        }
      }

      .titles {
        display: flex;
        height: 45px;
        line-height: 45px;
        background-color: rgb(239, 239, 239);
        font-size: 14px;
        margin-top: 16px;
        padding: 0 15px;

        div {
          flex: 1;


        }
      }
    }
  }
}
</style>
