export const breadcrumbList = [
  {
    name: '门店管理',
    path: ''
  },
  {
    name: '门店列表',
    path: ''
  }
]

export const contentTableConfig = {
  propList: [
    { prop: 'shopName', label: '门店名称', minWidth: '220', slotName: 'shopName' },
    { prop: 'shopKind', label: '类型', minWidth: '80',slotName:'shopKind' },
    {
      prop: 'openDate',
      label: '开业时间',
      minWidth: '160',
      slotName: 'openDate'
    },
    {
      prop: 'shopSquare',
      label: '建筑面积',
      minWidth: '120',
      slotName: 'shopSquare'
    },
    {
      prop: 'roomCount',
      label: '房间数',
      minWidth: '100',
      slotName: 'roomCount'
    },
    { label: '操作', minWidth: '200', slotName: 'handler' }
  ],
  showIndexColumn: true,
  showSelectColumn: false
}
