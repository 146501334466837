<template>
  <div class="top-content">
    <div class="add-shop">
      <el-button @click="hadleAddShopClick" type="primary" v-has="'shop:add'">添加门店</el-button>
      <div class="tips">当前共有{{ shopCount }}家门店</div>
    </div>


    <div class="form">
      <el-input @change="handleSearchChange" clearable v-model="ruleForm.keyword" placeholder="门店关键字" />
      <el-button @click="searchClick" class="searchBtn" type="primary">搜索</el-button>
    </div>
  </div>
</template>

<script lang="js">
import { useState } from '@/hooks'
import { defineComponent, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import emitter from '@/utils/eventbus'
export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    const router = useRouter()
    const shopState = useState('shop', ['shopCount'])

    const ruleForm = reactive({
      keyword: ''
    })
    const hadleAddShopClick = () => {
      router.push('/main/shop/add')
    }

    const searchClick = () => {
      emitter.emit('changeShopTopcontentSearchClick', ruleForm.keyword)
    }

    const handleSearchChange = (value) => {
      if (value.length == 0) {
        emitter.emit('changeShopTopcontentSearchClick', ruleForm.keyword)
      }
    }



    return {
      handleSearchChange,
      searchClick,
      ...shopState,
      hadleAddShopClick,
      ruleForm
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  margin-bottom: 20px;

  .add-shop {
    display: flex;
    align-items: center;

    .tips {
      margin-left: 10px;
      font-size: 14px;
      color: #666666;
    }
  }

  .tips {}

  .form {
    display: flex;

    .searchBtn {
      margin-left: 10px;
    }
  }
}
</style>
